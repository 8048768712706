<template>
  <div>
    <b-card title="NFT 관련">
      <!-- {{this.$store.state.myaccount.user.email}} -->
      <b-card-text> 기본정보,민팅정보만 사용가능합니다 <br>
      형님들 백서,로드맵은 링크로 가실건가요??     
      </b-card-text>
    </b-card>
    <b-card title="NFT 상세페이지(작업중) 🚀">
      <b-card-text>화이팅 </b-card-text>
    </b-card>
    <b-card title="대시보드(작업중) 🚀">
      <b-card-text>구글 애널리틱스 통계 </b-card-text>
    </b-card>
    <b-card title="회원관리(작업중) 🚀">
      <b-card-text>실시간 접속여부 </b-card-text>
    </b-card>
    

  </div>
</template>

<script>
import { BCard, BCardText, BLink } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
  },
}
</script>

<style>

</style>
